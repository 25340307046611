import axios from "axios";

/**
 * Renvoie la liste des layers d'un geodoc
 */
export const getLayersFromGeodoc = async (token, workspaceId, geodocId) => {
  if (!token || !workspaceId || !geodocId) {
    return;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspace/${workspaceId}/geodoc/${geodocId}/layers`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des layers :", error);
    throw error;
  }
};

/**
 * Renvoie la liste des layers d'un workspace
 */
export const getLayersFromWorkspace = async (token, workspaceId) => {
  if (!token || !workspaceId) {
    return;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/layers/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des layers :", error);
    throw error;
  }
};

/**
 * Permet de créer un layer dans un geodoc
 */
export const createLayerIntoGeodoc = async (
  token,
  workspaceId,
  geodocId,
  layerData
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs/${geodocId}/layers`,
      layerData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error("Erreur lors de la création du layer :", error);
    throw error;
  }
};

/**
 * Permet de retirer un layer d'un geodoc
 */
export const removeLayer = async (token, workspaceId, geodocId, layerId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodoc/${geodocId}/layers/${layerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la suppression du layer :", error);
    throw error;
  }
};

/**
 * Permet de supprimer un layer
 */
export const deleteLayer = async (token, workspaceId, geodocId, layerId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs/${geodocId}/layers/${layerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la suppression du layer :", error);
    throw error;
  }
};

/**
 * Permet de modifier un layer
 */
export const editLayer = async (
  token,
  workspaceId,
  geodocId,
  layerId,
  layerData
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs/${geodocId}/layers/${layerId}`,
      layerData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du layer :", error);
    throw error;
  }
};
