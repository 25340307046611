import React, { useState, useEffect } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useConfig } from "../../ConfigContext";

function TestPanel() {
  const { setUser, setToken, setError } = useConfig();

  // --------------------------- API
  const [status, setStatus] = useState();
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "status");
        const data = await response.json();
        setStatus(data.databaseStatus);
      } catch (error) {
        setStatus("Error");
      }
    };
    // Set an interval to fetch status every second
    const intervalId = setInterval(fetchStatus, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
    //fetchStatus();
  }, []);

  // --------------------------- LOGIN
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [responseLogin, setResponseLogin] = useState();
  const [responseLogout, setResponseLogout] = useState();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    onSignin(email, password);
  };

  const onSignin = async (mail, password) => {
    try {
      const url = process.env.REACT_APP_API_URL + "user/login";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mail, password }),
      });
      const data = await response.json();
      setResponseLogin(data);
      if (response.ok) {
        setUser(data.user);
        setToken(data.token);
        setResponseLogin(data);
      } else {
        setError(data.message || "Login failed");
        setResponseLogin(data);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "user/logout",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify({ token: user.token }), // Assuming the token is part of the user object
        }
      );
      const data = await response.json();
      setResponseLogout(data);
      if (response.ok) {
        setUser(null); // Clears the user state, effectively logging out the user
      } else {
        const error = data;
        alert("Logout failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  return (
    <>
      <h1>TESTS</h1>
      {/* ---------------- API*/}
      <h2>API</h2>
      {status}

      {/* ---------------- Login*/}
      <h2>Login</h2>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="please enter your email"
      />
      <TextField
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="please enter your password"
      />
      <Button
        variant="contained"
        className="submit-button"
        onClick={handleSubmit}
      >
        LogIn
      </Button>
      {responseLogin && (
        <Typography variant="body1" style={{ marginTop: "20px" }}>
          {responseLogin.error ? (
            <span style={{ color: "red" }}>Error: {responseLogin.error}</span>
          ) : (
            <pre>{JSON.stringify(responseLogin, null, 2)}</pre>
          )}
        </Typography>
      )}
      <h2>Change Password</h2>
      <h2>Logout</h2>
      <Button
        variant="contained"
        className="submit-button"
        onClick={handleLogout}
      >
        Logout
      </Button>
      {responseLogout && (
        <Typography variant="body1" style={{ marginTop: "20px" }}>
          {responseLogout.error ? (
            <span style={{ color: "red" }}>Error: {responseLogout.error}</span>
          ) : (
            <pre>{JSON.stringify(responseLogout, null, 2)}</pre>
          )}
        </Typography>
      )}

      {/* ---------------- Workspaces*/}
      <h2>Workspaces</h2>
      <Button
        variant="contained"
        className="submit-button"
        onClick={handleLogout}
      >
        Get Workspaces
      </Button>

      {/* ---------------- GeoDocs*/}
      <h2>GeoDocs</h2>

      {/* ---------------- Layers*/}
      <h2>Layers</h2>
    </>
  );
}

export default TestPanel;
