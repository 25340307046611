import React from "react";

import IconButton from "@mui/material/IconButton";
import MouseIcon from "@mui/icons-material/Mouse";
import DrawIcon from "@mui/icons-material/Draw";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import { useConfig } from "../../ConfigContext";
import "../../styles/control.css";

// https://mui.com/material-ui/material-icons/

function BandeauDessin() {
  const {
    color,
    setColor,
    mode,
    setMode,
    token,
    layerSelectedId,
    setIconPath,
    setCleanLayer,
  } = useConfig();

  return (
    <>
      {token && (
        <div className={`bottom-div ${layerSelectedId ? "highlighted" : ""}`}>
          <input
            type="color"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            style={{ width: "32px" }}
          />

          <IconButton
            aria-label="mouse"
            onClick={() => {
              setMode("mouse");
            }}
            style={{
              color: mode === "mouse" ? "#ba9e6c" : "#69aa87",
              border: mode === "mouse" ? "solid 2px #69aa87" : "",
              margin: "5px",
            }}
          >
            <MouseIcon />
          </IconButton>

          <IconButton
            aria-label="draw"
            onClick={() => {
              mode === "draw" ? setMode("mouse") : setMode("draw");
            }}
            style={{
              color: mode === "draw" ? "#ba9e6c" : "#69aa87",
              border: mode === "draw" ? "solid 2px #69aa87" : "",
            }}
          >
            <DrawIcon />
          </IconButton>

          <IconButton
            aria-label="circle"
            onClick={() => {
              mode === "circle" ? setMode("mouse") : setMode("circle");
            }}
            style={{
              color: mode === "circle" ? "#ba9e6c" : "#69aa87",
              border: mode === "circle" ? "solid 2px #69aa87" : "",
            }}
          >
            <PanoramaFishEyeIcon />
          </IconButton>

          <IconButton
            aria-label="polygon"
            onClick={() => {
              mode === "polygon" ? setMode("mouse") : setMode("polygon");
            }}
            style={{
              color: mode === "polygon" ? "#ba9e6c" : "#69aa87",
              border: mode === "polygon" ? "solid 2px #69aa87" : "",
            }}
          >
            <CropSquareIcon />
          </IconButton>

          <IconButton
            aria-label="location"
            onClick={() => {
              setCleanLayer(true);
            }}
            style={{
              color: mode === "location" ? "#ba9e6c" : "#69aa87",
              border: mode === "location" ? "solid 2px #69aa87" : "",
            }}
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            aria-label="icon"
            onClick={() => {
              mode === "marker" ? setMode("mouse") : setMode("marker");
            }}
            style={{
              color: mode === "marker" ? "#ba9e6c" : "#69aa87",
              border: mode === "marker" ? "solid 2px #69aa87" : "",
            }}
          >
            <StarIcon />
          </IconButton>
          {mode === "marker" && (
            <div className="icon-list">
              {[
                "man.svg",
                "woman.svg",
                "car.svg",
                "plane.svg",
                "boat.svg",
                "helicopter.svg",
                "fire.svg",
                "look.svg",
                "search.svg",
                "location.svg",
              ].map((icon, index) => (
                <img
                  key={index}
                  src={`icons/${icon}`}
                  alt={icon}
                  onClick={() => setIconPath(`icons/${icon}`)}
                  style={{
                    width: "32px",
                    height: "32px",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default BandeauDessin;
