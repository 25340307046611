import axios from "axios";

// Get all features of a layer
export const getFeatures = async (token, workspaceId, layerId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/layers/${layerId}/features`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching features:", error);
    throw error;
  }
};

// Get a specific feature by ID
export const getFeature = async (token, workspaceId, layerId, featureId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/layers/${layerId}/features/${featureId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching feature:", error);
    throw error;
  }
};

// Add a new feature to a layer
export const addFeature = async (token, workspaceId, layerId, featureData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/layers/${layerId}/features`,
      featureData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding feature:", error);
    throw error;
  }
};

// Update an existing feature
export const updateFeature = async (
  token,
  workspaceId,
  layerId,
  featureId,
  featureData
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/layers/${layerId}/features/${featureId}`,
      featureData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating feature:", error);
    throw error;
  }
};

// Delete a feature
export const deleteFeature = async (token, workspaceId, layerId, featureId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/layers/${layerId}/features/${featureId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting feature:", error);
    throw error;
  }
};
