import React, { useState } from "react";

import { Button, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/loginform.css";
import { useConfig } from "../../ConfigContext";

function RecoverPasswordForm({ setState }) {
  const { setMessage } = useConfig();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)); // Vérification simplifiée de l'email
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    onForgotPassword(email);
  };

  const onForgotPassword = async (mail) => {
    try {
      const url = process.env.REACT_APP_API_URL + "users/forgot-password";
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mail }),
      });

      setMessage("If you have an account, an email was sent.");
      /*if (response.ok) {
        alert("A password reset link has been sent to your email.");
      } else {
        const error = await response.json();
        alert("Password reset failed: " + error.message);
      }*/
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  return (
    <>
      <CloseIcon
        className="toggle-icon"
        onClick={() => setState("minimized")}
      />
      <Typography variant="h3" className="form-header">
        Recover Password
        <Button className="toggle-button" onClick={() => setState("signin")}>
          or Sign In ?
        </Button>
      </Typography>
      <TextField
        className="text-field"
        margin="normal"
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
        helperText={emailError ? "Please enter a valid email" : ""}
        placeholder="please enter your email"
      />

      <Button
        variant="contained"
        className="submit-button"
        disabled={emailError || email === ""}
        onClick={handleSubmit}
      >
        Recover Password
      </Button>
    </>
  );
}

export default RecoverPasswordForm;
