import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  IconButton,
  Menu,
  Divider,
  TextField,
  MenuItem,
  Accordion,
  Stack,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useConfig } from "../../ConfigContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LayersIcon from "@mui/icons-material/Layers";
import {
  createGeodocIntoWorkspace,
  deleteGeodoc,
  editGeodoc,
  getGeodocsFromWorkspace,
  getGeodocFromWorkspace,
} from "../../services/geodocService"; // Import des services

const GeodocsAccordion = () => {
  const {
    user,
    token,
    workspaceSelected,
    geodocs,
    setGeodocs,
    geodocSelectedId,
    setGeodocSelectedId,
    setLayers,
    setLayerSelectedId,
    setFollowUserId,
  } = useConfig();
  const [anchorElAddGeodoc, setAnchorElAddGeodoc] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [nameGeodoc, setNameGeodoc] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [geodocSelected, setGeodocSelected] = useState();

  useEffect(() => {
    if (token && workspaceSelected?._id) {
      fetchGeodocs();
    }
  }, [token, workspaceSelected]);

  // Fonction pour récupérer les geodocs
  const fetchGeodocs = async () => {
    try {
      const response = await getGeodocsFromWorkspace(
        token,
        workspaceSelected._id
      );
      if (response.status === 200) {
        setGeodocs(response.data || []);
        if (response.data.length > 0) {
          setGeodocSelectedId(response.data[0]._id);
        } else {
          setGeodocSelectedId(null);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des geodocs :", error);
    }
  };

  // si geodocSelectedId change, on met à jour le geodocSelected
  useEffect(() => {
    if (geodocs) {
      if (geodocSelectedId) {
        setLayerSelectedId(null);
        setFollowUserId(null);
        const fetchGeodoc = async () => {
          try {
            const geodocRetrieved = await getGeodocFromWorkspace(
              token,
              workspaceSelected._id,
              geodocSelectedId
            );

            //setGeodocSelectedId(geodocRetrieved._id);
            // mise a jour du geodoc dans geodocs
            setGeodocs((prevGeodocs) =>
              prevGeodocs.map((geodoc) =>
                geodoc._id === geodocSelectedId ? geodocRetrieved : geodoc
              )
            );
            setLayers(geodocRetrieved?.data?.layers || []);
          } catch (error) {
            console.error("Error fetching GeoDoc:", error);
          }
        };

        fetchGeodoc();

        setGeodocSelected(
          geodocs.find((geodoc) => geodoc._id === geodocSelectedId)
        );
      } else {
        setGeodocSelected(null);
      }
    }
  }, [geodocSelectedId]);

  const handleMenuOpen = (setAnchor) => (geodoc) => {
    setAnchor(geodoc.currentTarget);
  };

  const handleMenuClose = (setAnchor, resetState) => () => {
    setAnchor(null);
    if (resetState) resetState();
  };

  // Fonction pour ajouter un GeoDoc
  const handleAddGeodoc = async () => {
    if (!nameGeodoc) return;
    try {
      const response = await createGeodocIntoWorkspace(
        token,
        workspaceSelected._id,
        nameGeodoc
      ); // Appel du service
      if (response.status === 201) {
        setGeodocs((prevGeodocs) => [...prevGeodocs, response.data]);
        setGeodocSelectedId(response.data._id);
        setGeodocSelected(response.data);
        setNameGeodoc("");
        setAnchorElAddGeodoc(null);
        setExpanded(false);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du GeoDoc :", error);
    }
  };

  // Fonction pour supprimer un GeoDoc
  const handleDeleteGeodoc = async (geodocId) => {
    try {
      await deleteGeodoc(token, workspaceSelected._id, geodocId); // Appel du service
      setGeodocs((prevGeodocs) =>
        prevGeodocs.filter((geodoc) => geodoc._id !== geodocId)
      );
      setGeodocSelectedId(null);
    } catch (error) {
      console.error("Erreur lors de la suppression du GeoDoc :", error);
    }
  };

  // Fonction pour renommer un GeoDoc
  const handleRenameGeodoc = async (geodocId, newName) => {
    try {
      const updatedGeodoc = await editGeodoc(
        token,
        workspaceSelected._id,
        geodocId,
        newName
      );

      // Appel du service
      setGeodocs((prevGeodocs) =>
        prevGeodocs.map((geodoc) =>
          geodoc._id === geodocId ? updatedGeodoc : geodoc
        )
      );
      //setGeodocSelectedId(updatedGeodoc);
      setIsRenaming(false);
    } catch (error) {
      console.error("Erreur lors du renommage du GeoDoc :", error);
    }
  };

  const renderAddGeodocMenu = () => (
    <Menu
      anchorEl={anchorElAddGeodoc}
      open={Boolean(anchorElAddGeodoc)}
      onClose={handleMenuClose(setAnchorElAddGeodoc)}
    >
      <div className="add-form">
        <TextField
          label="Name Geodoc"
          value={nameGeodoc}
          onChange={(e) => setNameGeodoc(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          fullWidth
          margin="dense"
        />
        <Button
          onClick={handleAddGeodoc}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
        >
          Add
        </Button>
        <Button
          onClick={handleMenuClose(setAnchorElAddGeodoc)}
          style={{ marginTop: "10px" }}
        >
          Cancel
        </Button>
      </div>
    </Menu>
  );

  const renderGeodocsListAccordion = () => {
    return (
      <AccordionDetails>
        <Stack spacing={2}>
          {geodocs.length > 1 &&
            geodocs.map(
              (geodoc, index) =>
                geodoc._id !== geodocSelectedId && (
                  <Button
                    key={geodoc._id || index}
                    onClick={() => {
                      setExpanded(false);
                      setGeodocSelectedId(geodoc._id);
                    }}
                  >
                    {geodoc.profile.name}
                  </Button>
                )
            )}
          {geodocs.length > 1 && <Divider sx={{ my: 0.5 }} />}
          <Button
            aria-label="Add GeoDoc"
            onClick={handleMenuOpen(setAnchorElAddGeodoc)}
          >
            New GeoDoc
          </Button>
        </Stack>
      </AccordionDetails>
    );
  };

  const renderSettingsMenu = () => (
    <Menu
      anchorEl={anchorElSettings}
      open={Boolean(anchorElSettings)}
      onClose={handleMenuClose(setAnchorElSettings, () => {
        setIsRenaming(false);
        setIsDeleting(false);
      })}
    >
      {!isRenaming &&
        !isDeleting && [
          <MenuItem key="update" onClick={() => setIsRenaming(true)}>
            <Button>Update GeoDoc Name</Button>
          </MenuItem>,
          <MenuItem key="delete" onClick={() => setIsDeleting(true)}>
            <Button>Delete GeoDoc</Button>
          </MenuItem>,
        ]}
      {isRenaming && (
        <MenuItem>
          <div className="add-form">
            <TextField
              label="Name GeoDoc"
              value={nameGeodoc}
              onChange={(e) => setNameGeodoc(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              fullWidth
              margin="dense"
            />
            <Button
              variant="contained"
              onClick={() => handleRenameGeodoc(geodocSelectedId, nameGeodoc)}
            >
              Update name
            </Button>
          </div>
        </MenuItem>
      )}
      {isDeleting && (
        <MenuItem onClick={() => handleDeleteGeodoc(geodocSelectedId)}>
          <Button variant="contained" color="error">
            Delete GeoDoc
          </Button>
        </MenuItem>
      )}
    </Menu>
  );
  return (
    workspaceSelected &&
    geodocs && (
      <>
        {geodocs.length === 0 ? (
          <>
            <Card
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                padding: "4px",
              }}
            >
              <TextField
                label="Name GeoDoc"
                value={nameGeodoc}
                onChange={(e) => setNameGeodoc(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                fullWidth
                margin="dense"
              />
              <Button
                onClick={handleAddGeodoc}
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Add your first GeoDoc
              </Button>
            </Card>
          </>
        ) : (
          <>
            <Accordion
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                padding: "4px",
              }}
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
            >
              {geodocSelected &&
                geodocSelected.profile &&
                geodocSelected.profile.name && (
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <LayersIcon />
                    {geodocSelected.profile.name}
                  </AccordionSummary>
                )}
              {renderGeodocsListAccordion()}
              <div className="left-top-div">{renderAddGeodocMenu()}</div>
            </Accordion>
            {geodocSelected && (
              <>
                <IconButton
                  onClick={handleMenuOpen(setAnchorElSettings)}
                  className="icon-button"
                >
                  <MoreVertIcon />
                </IconButton>
                {renderSettingsMenu()}
              </>
            )}
          </>
        )}
      </>
    )
  );
};

export default GeodocsAccordion;
