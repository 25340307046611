import React, { useState } from "react";
import UserList from "./UserList";
import WorkspaceList from "./WorkspaceList";
import LayerList from "./LayerList";

function AdminPanel() {
  return (
    <>
      <h1>USERS</h1>
      <UserList></UserList>
      <h1>WORKSPACES</h1>
      <WorkspaceList></WorkspaceList>
      <h1>LAYERS</h1>
      <LayerList></LayerList>
    </>
  );
}

export default AdminPanel;
