import React, { useState } from "react";
import { useParams } from "react-router-dom";
import MapFeatures from "./Map/MapFeatures";
import BandeauDessin from "./Map/Control";
import Detail from "./Map/Detail";
import Version from "./Version";
import ConfigPanel from "./AreaConfig/ConfigPanel";
import UserPanel from "./AreaShare/UserPanel";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import { useConfig } from "../ConfigContext";
import PasswordChangeDialog from "./PasswordChangeDialog";
import TermsOfUse from "./Signin/TermsOfUse";
import LoginForm from "./Signin/LoginForm";
import Search from "./AreaWork/Search";
import { acceptWorkspaceInvitation } from "../services/workspaceService";

function Home() {
  const { message, setMessage, error, setError, token } = useConfig();
  const [actualLocation, setActualLocation] = useState(null);
  const [moveTo, setMoveTo] = useState(null);
  const [isVisibleTOU, setIsVisibleTOU] = useState(false);

  const { funkytion, resetId, tokenInvit } = useParams(); // TODO : Vérifier que ca fonctionne, j'ai remplacer token par resetId et je sais pas si les chemins n'ont pas changé aussi

  // si tokenInvit est défini, on valide l'invitation avec
  /*if (token && tokenInvit) {
    try {
      acceptWorkspaceInvitation(token, tokenInvit);
      console.log("Invitation acceptée");
    } catch (error) {}
  }*/

  return (
    <div>
      {funkytion === "reset-password" && resetId && (
        <PasswordChangeDialog token={resetId}></PasswordChangeDialog>
      )}
      <Dialog
        open={Boolean(error)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Error
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setError("")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error}
            </DialogContentText>
          </DialogContent>
        </DialogContent>
      </Dialog>
      <Dialog open={Boolean(message)}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Message
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setMessage("")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
        </DialogContent>
      </Dialog>

      <BandeauDessin />
      <Detail />
      <Version />
      {token ? (
        <>
          <UserPanel setIsVisibleTOU={setIsVisibleTOU} />
          <ConfigPanel actualLocation={actualLocation} setMoveTo={setMoveTo} />

          {/*<Search />*/}
        </>
      ) : isVisibleTOU ? (
        /* Je veux voir les Terms of Use */
        <TermsOfUse setIsVisibleTOU={setIsVisibleTOU}></TermsOfUse>
      ) : (
        /* Je veux me loguer */
        <LoginForm setIsVisibleTOU={setIsVisibleTOU} />
      )}
      <MapFeatures moveTo={moveTo} setActualLocation={setActualLocation} />
    </div>
  );
}

export default Home;
