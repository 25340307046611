import axios from "axios";

/**
 * Renvoie la liste des backgrounds d'un geodoc en particulier
 */
export const getBackgroundsFromGeodoc = async (
  token,
  workspaceId,
  geodocId
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspace/${workspaceId}/geodoc/${geodocId}/backgrounds`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des backgrounds :", error);
    throw error;
  }
};

/**
 * Permet de créer un background dans un geodoc
 */
export const createBackground = async (token, workspaceId, backgroundData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/backgrounds/`,
      backgroundData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la création du background :", error);
    throw error;
  }
};

/**
 * Permet de supprimer un background
 */
export const deleteBackground = async (
  token,
  workspaceId,
  geodocId,
  backgroundId
) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}workspace/${workspaceId}/geodoc/${geodocId}/background/${backgroundId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du background :", error);
    throw error;
  }
};

/**
 * Permet de modifier un background
 */
export const editBackground = async (
  token,
  workspaceId,
  geodocId,
  backgroundId,
  backgroundData
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}workspace/${workspaceId}/geodoc/${geodocId}/background/${backgroundId}`,
      backgroundData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du background :", error);
    throw error;
  }
};
