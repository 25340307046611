// LayersPanel.jsx

import React, { useState } from "react";
import { Card, Button, Menu, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useConfig } from "../../../ConfigContext";
import AddLayerForm from "./AddLayerForm";
import { deleteLayer } from "../../../services/layerService";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const LayersPanel = () => {
  const {
    token,
    workspaceSelected,
    layers,
    setLayers,
    geodocSelectedId,
    layerSelectedId,
    setLayerSelectedId,
  } = useConfig();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteLayer = async (layer) => {
    try {
      const response = await deleteLayer(
        token,
        workspaceSelected._id,
        geodocSelectedId,
        layer._id
      );
      if (response.status === 200) {
        setLayers(layers.filter((item) => item._id !== layer._id));
      }
    } catch (error) {
      console.error("Error removing layer:", error);
    }
  };

  const layerList = () => {
    return (
      layers && (
        <div>
          {layers.map((value, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  const updatedItems = layers.map((item, i) =>
                    i === index
                      ? { ...item, visibility: !item.visibility }
                      : item
                  );
                  setLayers(updatedItems);
                }}
              >
                {value.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
              <Button
                disabled={value.type === "STORED"}
                onClick={() => {
                  layerSelectedId === value._id
                    ? setLayerSelectedId(null)
                    : setLayerSelectedId(value._id);
                }}
                style={{
                  fontWeight: layerSelectedId === value._id ? "bold" : "normal",
                }}
              >
                {value.name}
              </Button>
              <IconButton
                onClick={() => {
                  handleDeleteLayer(value);
                }}
              >
                {value.type !== "STORED" ? (
                  <DeleteIcon />
                ) : (
                  <RemoveCircleOutlineIcon />
                )}
              </IconButton>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    geodocSelectedId && (
      <Card
        style={{
          marginTop: "80px",
          marginLeft: "10px",
          maxHeight: "calc(100vh - 320px)",
          overflowY: "auto",
          padding: "4px",
        }}
      >
        {layerList()}
        <div className="left-top-div">
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <AddLayerForm handleMenuClose={handleMenuClose} />
          </Menu>
        </div>

        <Button aria-label="Add Layer" onClick={handleMenuOpen}>
          Add Layer
        </Button>
      </Card>
    )
  );
};

export default LayersPanel;
