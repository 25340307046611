import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Circle,
  Polygon,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useConfig } from "../../ConfigContext";
import { WMSTileLayer } from "react-leaflet";
import DynamicMarker from "./DynamicMarker";
import UserMarker from "./UserMarker";
import { addFeature } from "../../services/featureService";

const MapFeatures = ({ moveTo, setActualLocation }) => {
  const {
    color,
    token,
    mode,
    setMode,
    mapInit,
    features,
    setFeatures,
    setIdSelectedFeature,
    layers,
    setLayers,
    layerSelectedId,
    socket,
    backgroundSelected,
    iconPath,
    setIconPath,
    workspaceSelected,
    cleanLayer,
    setCleanLayer,
    user,
    followUserId,
  } = useConfig();

  const [map, setMap] = useState(null);
  const [firstPoint, setFirstPoint] = useState(true);
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [circleStart, setCircleStart] = useState(null);
  const [radius, setRadius] = useState(0);
  const [positionMarker, setPositionMarker] = useState(null);
  const [oldMode, setOldMode] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [markerSize, setMarkerSize] = useState(64); // Taille par défaut du marqueur

  const [pixelSizeInMeters, setPixelSizeInMeters] = useState(null);

  const [isZooming, setIsZooming] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [userPositions, setUserPositions] = useState([]);
  const [userPositionMarkers, setUserPositionMarkers] = useState([]);
  const [lastSendTime, setLastSendTime] = useState(0);
  const oldFollowRef = useRef({});

  useEffect(() => {
    if (cleanLayer) {
      setFeatures([]);
      setMarkers([]);
      setPolygonCoordinates([]);
      setPolylineCoordinates([]);
      setCircleStart(null);
      setPositionMarker(null);
      setRadius(0);
      setFirstPoint(true);
      setCleanLayer(false);
      setMode("mouse");
    }
  }, [cleanLayer]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setMode("mouse");
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setMode]);

  useEffect(() => {
    if (socket) {
      const handlePositions = (positions) => {
        setUserPositions(positions);
      };
      socket.on("positions", handlePositions);
      return () => {
        socket.off("positions", handlePositions);
      };
    }
  }, [socket]);

  useEffect(() => {
    const actualUser = user;
    // afficher tous les marqueurs des utilisateurs sauf l'utilisateur connecté
    setUserPositionMarkers(
      userPositions
        .filter((userFilter) => userFilter[0] !== actualUser.id)
        .map((userTmp) => {
          return {
            coordinates: [[userTmp[1].x, userTmp[1].y]],
            name: userTmp[1].n,
          };
        })
    );

    // si followUserId est défini, suivre l'utilisateur
    if (followUserId) {
      const userToFollow = userPositions.find(
        (user) => user[0] === followUserId
      );
      if (
        userToFollow &&
        (oldFollowRef.current.x !== userToFollow[1].xc ||
          oldFollowRef.current.y !== userToFollow[1].yc ||
          oldFollowRef.current.z !== userToFollow[1].z)
      ) {
        map.flyTo([userToFollow[1].xc, userToFollow[1].yc], userToFollow[1].z);
        oldFollowRef.current.x = userToFollow[1].xc;
        oldFollowRef.current.y = userToFollow[1].yc;
        oldFollowRef.current.z = userToFollow[1].z;
      }
    }
  }, [followUserId, userPositions, map]);

  const onMove = useCallback(
    (e) => {
      if (!map) return;
      // si le bouton de la souris est enfoncé, alors passé le mode isMoving a true
      if (e.originalEvent.buttons > 0) {
        setIsMoving(true);
      }

      const mouseCoord = [e.latlng.lat, e.latlng.lng];
      switch (mode) {
        case "draw":
          if (!firstPoint) {
            const coords = features[features.length - 1].coordinates;
            const lastPoint = coords[coords.length - 1];
            setPolylineCoordinates([lastPoint, mouseCoord]);
          }
          break;
        case "circle":
          if (!firstPoint) {
            setRadius(map.distance(circleStart, mouseCoord));
          }
          break;
        case "polygon":
          if (polygonCoordinates.length > 0) {
            setPolygonCoordinates((prev) => {
              const updatedCoords = prev.slice();
              updatedCoords[updatedCoords.length - 1] = mouseCoord;
              return updatedCoords;
            });
          }
          break;
        case "marker":
          if (iconPath) {
            setPositionMarker(mouseCoord);
          }
          break;
        default:
          if (socket && socket.connected && Date.now() - lastSendTime > 100) {
            const currentCenter = map.getCenter();
            const currentZoom = map.getZoom();
            socket.emit("p", {
              x: mouseCoord[0].toFixed(3), // position mouse x user
              y: mouseCoord[1].toFixed(3), // position mouse y user
              n: user.firstname.charAt(0) + user.lastname.charAt(0), // user badge
              xc: currentCenter.lat, // center x
              yc: currentCenter.lng, // center y
              z: currentZoom, // zoom
            });
            setLastSendTime(Date.now());
          }
          break;
      }
    },
    [
      map,
      mode,
      firstPoint,
      features,
      circleStart,
      polygonCoordinates,
      iconPath,
      socket,
    ]
  );

  const onClick = useCallback(
    (e) => {
      if (!map) return;
      const mouseCoord = [e.latlng.lat, e.latlng.lng];
      switch (mode) {
        case "marker":
          if (!iconPath) return;
          if (!layerSelectedId) {
            setMarkers((prev) => [
              ...prev,
              {
                coordinates: [mouseCoord],
                iconPath: iconPath,
                color: color,
                size: markerSize * pixelSizeInMeters, // Enregistrer la taille actuelle
              },
            ]);
          } else {
            console.log("new feature");
            const newFeature = {
              name: "New marker",
              type: "Marker",
              coordinates: [mouseCoord],
              iconPath: iconPath,
              color: color,
              size: markerSize * pixelSizeInMeters, // Enregistrer la taille actuelle
            };
            try {
              addFeature(
                token,
                workspaceSelected._id,
                layerSelectedId,
                newFeature
              );
              // ajouter au layer selectionné le newfeature
              /*setLayers(
                layers.map((layer) => {
                  if (layer._id === layerSelectedId) {
                    return {
                      ...layer,
                      features: [...layer.features, newFeature],
                    };
                  }
                  return layer;
                })
              );*/
              setLayers((prevLayers) =>
                prevLayers.map((layer) =>
                  layer._id === layerSelectedId
                    ? {
                        ...layer,
                        features: [...layer.features, newFeature],
                      }
                    : layer
                )
              );
            } catch (error) {
              console.error("Error adding feature : ", error);
            }
          }

          setIconPath(null);
          break;
        case "draw":
          if (firstPoint) {
            const newFeature = {
              id: Date.now(),
              type: "Polyline",
              coordinates: [mouseCoord],
              color: color,
              infos: { length: 0 },
            };
            setFeatures((prev) => [...prev, newFeature]);
          } else {
            setFeatures((prev) =>
              prev.map((feature, idx) => {
                if (idx === prev.length - 1 && feature.type === "Polyline") {
                  const newLength =
                    feature.infos.length +
                    map.distance(
                      mouseCoord,
                      feature.coordinates[feature.coordinates.length - 1]
                    );
                  return {
                    ...feature,
                    coordinates: [...feature.coordinates, mouseCoord],
                    infos: { length: newLength },
                  };
                }
                return feature;
              })
            );
          }
          setFirstPoint(false);
          break;
        case "circle":
          if (firstPoint) {
            setCircleStart(mouseCoord);
            setFirstPoint(false);
          } else {
            const newRadius = map.distance(circleStart, mouseCoord);
            const newFeature = {
              name: "New circle",
              id: Date.now(),
              type: "Circle",
              coordinates: [circleStart],
              color: color,
              radius: newRadius,
              infos: {
                diameter: newRadius * 2,
                area: Math.PI * newRadius ** 2,
              },
            };
            if (layerSelectedId) {
              // ajout feature au layer courant
              setLayers((prevLayers) =>
                prevLayers.map((layer) =>
                  layer._id === layerSelectedId
                    ? {
                        ...layer,
                        features: [...layer.features, newFeature],
                      }
                    : layer
                )
              );

              addFeature(token, workspaceSelected._id, layerSelectedId, {
                name: "New circle",
                type: "Circle",
                coordinates: [circleStart],
                radius: newRadius,
                color: color,
              });
            } else {
              setFeatures((prev) => [...prev, newFeature]);
            }
            setCircleStart(null);
            setRadius(0);
            setFirstPoint(true);
          }
          break;
        case "polygon":
          if (polygonCoordinates.length === 0) {
            setPolygonCoordinates([mouseCoord]);
          } else {
            setPolygonCoordinates((prev) => [...prev, mouseCoord]);
          }
          break;
        default:
          break;
      }
    },
    [
      map,
      mode,
      firstPoint,
      features,
      color,
      circleStart,
      setFeatures,
      iconPath,
      setIconPath,
      markerSize,
      layers,
    ]
  );

  const getPixelSizeInMeters = (latlng) => {
    const point = map.latLngToContainerPoint(latlng);
    const pointRight = L.point(point.x + 1, point.y);
    const latlngRight = map.containerPointToLatLng(pointRight);
    const distance = latlng.distanceTo(latlngRight); // en mètres
    return distance;
  };

  const onZoomEnd = useCallback(() => {
    const center = map.getCenter();

    // Calculer la taille d'un pixel en mètres au centre de la carte
    const pixelSize = getPixelSizeInMeters(center);
    setPixelSizeInMeters(pixelSize);
    setIsZooming(false);
  }, [map]);

  const onZoomStart = useCallback(() => {
    setIsZooming(true);
  }, []);

  const onMouseDown = useCallback(() => {
    setIdSelectedFeature(null);
  }, [setIdSelectedFeature]);

  const onMouseUp = useCallback(() => {
    setIsMoving(false);
  }, []);

  useEffect(() => {
    if (!map) return;

    const center = map.getCenter();
    const pixelSize = getPixelSizeInMeters(center);
    setPixelSizeInMeters(pixelSize);

    map.on("click", onClick);
    map.on("mousemove", onMove);
    map.on("mousedown", onMouseDown);
    map.on("mouseup", onMouseUp);
    map.on("zoomstart", onZoomStart);
    map.on("zoomend", onZoomEnd);
    return () => {
      map.off("click", onClick);
      map.off("mousemove", onMove);
      map.off("mousedown", onMouseDown);
      map.off("mouseup", onMouseUp);
      map.off("zoomstart", onZoomStart);
      map.off("zoomend", onZoomEnd);
    };
  }, [map, onClick, onMove, setIdSelectedFeature, onZoomEnd]);

  useEffect(() => {
    if (!map) return;
    if (oldMode !== mode) setFirstPoint(true);
    if (oldMode === "draw" && features.length > 0) {
      const newFeature = {
        id: Date.now(),
        name: "New polyline",
        type: "Polyline",
        coordinates: features[features.length - 1].coordinates,
        color: color,
      };
      if (layerSelectedId) {
        // ajout feature au layer courant
        addFeature(token, workspaceSelected._id, layerSelectedId, newFeature);
      }
    }
    if (oldMode === "polygon" && polygonCoordinates.length > 1) {
      const newFeature = {
        id: Date.now(),
        type: "Polygon",
        coordinates: polygonCoordinates.slice(0, -1),
        color: color,
      };
      if (layerSelectedId) {
        // ajout feature au layer courant
        setLayers((prevLayers) =>
          prevLayers.map((layer) =>
            layer._id === layerSelectedId
              ? {
                  ...layer,
                  features: [...layer.features, newFeature],
                } // ajouter au layer selectionné le newfeature
              : layer
          )
        );
        addFeature(token, workspaceSelected._id, layerSelectedId, {
          name: "New polygon",
          type: "Polygon",
          coordinates: polygonCoordinates.slice(0, -1),
          color: color,
        });
      } else {
        setFeatures((prev) => [...prev, newFeature]);
      }
      setPolygonCoordinates([]);
    }
    if (mode === "draw" || mode === "circle" || mode === "polygon") {
      map.getContainer().style.cursor = "crosshair";
    } else {
      map.getContainer().style.cursor = "";
    }
    setOldMode(mode);
  }, [mode, oldMode, color, map, setFeatures]);

  useEffect(() => {
    if (!map) return;
    const updateLocation = () => {
      const centerTmp = map.getCenter();
      const zoomTmp = map.getZoom();
      setActualLocation({
        lat: centerTmp.lat,
        lng: centerTmp.lng,
        zoom: zoomTmp,
      });
    };
    updateLocation();
    map.on("moveend", updateLocation);
    return () => {
      map.off("moveend", updateLocation);
    };
  }, [map, setActualLocation]);

  useEffect(() => {
    if (!map || !moveTo) return;
    map.flyTo([moveTo.lat, moveTo.lng], moveTo.zoom);
  }, [moveTo, map]);

  const handleFeatureClick = (id) => {
    setIdSelectedFeature(id);
  };

  const displayFeatures = (features) => {
    return features.map((feature) => {
      switch (feature.type) {
        case "Marker":
          const sizeInPixels = feature.size / pixelSizeInMeters;
          return (
            <DynamicMarker
              key={feature.id}
              coordinates={feature.coordinates[0]}
              iconPath={feature.iconPath}
              color={feature.color}
              size={sizeInPixels}
            />
          );
        case "Polyline":
          return (
            <Polyline
              key={feature.id}
              positions={feature.coordinates}
              color={feature.color}
            />
          );
        case "Polygon":
          return (
            <Polygon
              key={feature.id}
              positions={feature.coordinates}
              color={feature.color}
            />
          );
        case "Circle":
          return (
            <Circle
              key={feature.id}
              center={feature.coordinates[0]}
              radius={feature.radius}
              color={feature.color}
              onClick={() => handleFeatureClick(feature.id)}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <MapContainer
      center={mapInit.center}
      zoom={mapInit.zoom}
      zoomControl={false}
      style={{ height: "100vh", width: "100%" }}
      ref={setMap}
    >
      {/* -------------------------------------------------------------------------- BACKGROUNDS */}
      <TileLayer
        url={
          backgroundSelected
            ? backgroundSelected.url
            : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        }
        attribution={
          backgroundSelected
            ? backgroundSelected.attribution
            : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
      />

      {/* -------------------------------------------------------------------------- LAYERS */}
      {!isZooming &&
        !isMoving &&
        layers &&
        layers.map((layer) => {
          if (layer.visibility) {
            switch (layer.type) {
              case "STORED":
                return displayFeatures(layer.layerStoreId.features);
              case "FeatureCollection":
                return displayFeatures(layer.features);
              case "WMS":
                return (
                  <WMSTileLayer
                    key={layer.id}
                    url={layer.url}
                    layers={layer.layers}
                    format="image/png"
                    transparent={true}
                    attribution={layer.attribution}
                  />
                );
              case "TMS":
                return (
                  <TileLayer
                    key={layer.id}
                    url={layer.url}
                    format="image/png"
                    transparent={true}
                    attribution={layer.attribution}
                  />
                );
              default:
                return null;
            }
          }
          return null;
        })}

      {/* -------------------------------------------------------------------------- ELEMENTS TEMPORAIRES*/}

      {/* Affichage des features temporaires */}
      {!isZooming &&
        !isMoving &&
        features.map((feature) => {
          const eventHandlers = {
            click: () => handleFeatureClick(feature.id),
          };
          const tooltipContent =
            feature.name || `${feature.type} ${feature.id}`;
          switch (feature.type) {
            case "Polyline":
              return (
                <Polyline
                  key={feature.id}
                  positions={feature.coordinates}
                  color={feature.color}
                  eventHandlers={eventHandlers}
                  pathOptions={{ dashArray: "5, 10" }}
                >
                  <Tooltip>{tooltipContent}</Tooltip>
                </Polyline>
              );
            case "Circle":
              return (
                <Circle
                  key={feature.id}
                  center={feature.coordinates[0]}
                  radius={feature.radius}
                  color={feature.color}
                  eventHandlers={eventHandlers}
                  pathOptions={{ dashArray: "5, 10" }}
                >
                  <Tooltip>{tooltipContent}</Tooltip>
                </Circle>
              );
            case "Polygon":
              return (
                <Polygon
                  key={feature.id}
                  positions={feature.coordinates}
                  color={feature.color}
                  eventHandlers={eventHandlers}
                  pathOptions={{ dashArray: "5, 10" }}
                >
                  <Tooltip>{tooltipContent}</Tooltip>
                </Polygon>
              );
            default:
              return null;
          }
        })}
      {mode === "draw" && polylineCoordinates.length > 0 && (
        <Polyline
          positions={polylineCoordinates}
          color={color}
          pathOptions={{ dashArray: "5, 10" }}
        />
      )}
      {mode === "polygon" && polygonCoordinates.length > 0 && (
        <Polygon
          positions={polygonCoordinates}
          color={color}
          pathOptions={{ dashArray: "5, 10" }}
        />
      )}
      {circleStart && (
        <Circle
          center={circleStart}
          radius={radius}
          color={color}
          pathOptions={{ dashArray: "5, 10" }}
        />
      )}

      {/* Affichage du marqueur */}
      {mode === "marker" && iconPath && positionMarker && (
        <DynamicMarker
          coordinates={positionMarker}
          iconPath={iconPath}
          color={color}
          size={64} // Passer la taille actuelle
        />
      )}

      {/* Affichage des marqueurs temporaires*/}
      {!isZooming &&
        !isMoving &&
        markers.map((marker, index) => {
          const sizeInPixels = marker.size / pixelSizeInMeters; // Convertir la taille en pixels
          return (
            <DynamicMarker
              key={index}
              coordinates={marker.coordinates[0]}
              iconPath={marker.iconPath}
              color={marker.color}
              size={sizeInPixels} // Utiliser la taille enregistrée
            />
          );
        })}

      {/* Affichage des marqueurs de position des utilisateurs avec son nom*/}
      {!isZooming &&
        !isMoving &&
        userPositionMarkers.map((marker, index) => {
          return (
            <UserMarker
              key={index}
              coordinates={marker.coordinates[0]}
              name={marker.name}
            ></UserMarker>
          );
        })}
    </MapContainer>
  );
};

export default MapFeatures;
