import axios from "axios";

/**
 * Renvoie la liste des géodocs d'un workspace en particulier
 */
export const getGeodocsFromWorkspace = async (token, workspaceId) => {
  if (!token || !workspaceId) return;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la récupération des géodocs :", error);
    throw error;
  }
};

/**
 * Renvoie le géodoc
 */
export const getGeodocFromWorkspace = async (token, workspaceId, geodocId) => {
  if (!token || !workspaceId || !geodocId) return;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs/${geodocId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du géodoc :", error);
    throw error;
  }
};

/**
 * Permet de créer un géodoc dans un workspace
 */
export const createGeodocIntoWorkspace = async (
  token,
  workspaceId,
  nameGeodoc
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs`,
      { name: nameGeodoc },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la création du géodoc :", error);
    throw error;
  }
};

/**
 * Permet de supprimer un géodoc
 */
export const deleteGeodoc = async (token, workspaceId, geodocId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}workspace/${workspaceId}/geodoc/${geodocId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du géodoc :", error);
    throw error;
  }
};

/**
 * Permet de modifier un géodoc
 */
export const editGeodoc = async (token, workspaceId, geodocId, newName) => {
  try {
    console.log("editGeodoc", newName);
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/geodocs/${geodocId}`,
      { profile: { name: newName } },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du géodoc :", error);
    throw error;
  }
};
