import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import "../../styles/user.css";
import { Card, Stack, Button } from "@mui/material";
import { useConfig } from "../../ConfigContext";

/**
 * Affiche le panel de l'Inbox.
 * @param {parms} desc
 */
function Inbox() {
  return (
    <>
      <h1>Inbox</h1>
      <Stack direction="row" spacing={2}>
        <Button>Unread</Button>
        <Button>Read</Button>
      </Stack>
    </>
  );
}

export default Inbox;
