import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useConfig } from "../../ConfigContext";

import { createBackground } from "../../services/backgroundService";

function BackgroundPanel() {
  const {
    workspaceSelected,
    backgroundSelected,
    setBackgroundSelected,
    setWorkspaceSelected,
    token,
  } = useConfig();
  const [openDialog, setOpenDialog] = useState(false);
  const [newBackground, setNewBackground] = useState({
    name: "",
    url: "",
    attribution: "",
  });

  useEffect(() => {
    if (
      workspaceSelected &&
      workspaceSelected.data.backgrounds &&
      workspaceSelected.data.backgrounds.length > 0
    ) {
      setBackgroundSelected(workspaceSelected.data.backgrounds[0]);
    }
  }, [workspaceSelected]);

  const handleAddBackground = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewBackground({ name: "", url: "", attribution: "" });
  };

  const handleSaveBackground = async () => {
    try {
      const response = await createBackground(token, workspaceSelected._id, {
        name: newBackground.name,
        url: newBackground.url,
        attribution: newBackground.attribution,
      });
      /*await axios.post(
        `${process.env.REACT_APP_API_URL}workspaces/` +
          workspaceSelected._id +
          `/backgrounds`,
        {
          name: newBackground.name,
          url: newBackground.url,
          attribution: newBackground.attribution,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );*/

      // Update the workspaceSelected with the new background
      if (response.status === 201) {
        setWorkspaceSelected({
          ...workspaceSelected,
          data: {
            ...workspaceSelected.data,
            backgrounds: [
              ...workspaceSelected.data.backgrounds,
              { ...newBackground },
            ],
          },
        });
      }

      //workspaceSelected.data.backgrounds.push({ ...newBackground });
      setNewBackground({ name: "", url: "", attribution: "" });
    } catch (error) {
      console.error("Error updating layer:", error);
    }
    // Implement the logic to save the new background
    // For example, call an API to save the background to the server
    // After saving, update the workspaceSelected with the new background

    // Close the dialog
    handleCloseDialog();
  };

  const handleChange = (e) => {
    setNewBackground({
      ...newBackground,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {workspaceSelected && (
        <Card
          style={{
            marginTop: "20px",
            marginLeft: "10px",
            padding: "4px",
          }}
        >
          {workspaceSelected.data &&
            workspaceSelected.data.backgrounds &&
            workspaceSelected.data.backgrounds.length === 0 &&
            "No BackGround"}
          {workspaceSelected &&
            workspaceSelected.data &&
            workspaceSelected.data.backgrounds &&
            workspaceSelected.data.backgrounds.map((bg, index) => (
              <Button
                key={bg._id || `background-${index}`}
                onClick={() => setBackgroundSelected(bg)}
                disabled={bg === backgroundSelected}
              >
                {bg.name}
              </Button>
            ))}
          <Button onClick={() => handleAddBackground()}>
            <AddIcon />
          </Button>
        </Card>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Background</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Background Name"
            fullWidth
            value={newBackground.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="url"
            label="Background Url"
            fullWidth
            value={newBackground.url}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="attribution"
            label="Background Attribution"
            fullWidth
            value={newBackground.attribution}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSaveBackground}
            disabled={!newBackground.name || !newBackground.url}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BackgroundPanel;
