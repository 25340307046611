import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import "../../styles/loginform.css";
import Minimized from "./Minimized";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import RecoverPasswordForm from "./RecoverPasswordForm";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useConfig } from "../../ConfigContext";
import { Card } from "@mui/material";

/**
 * Affiche le login au centre
 */
function LoginForm({ onSignin, onSignUp, onForgotPassword, setIsVisibleTOU }) {
  const { setModeGlobal, modeGlobal } = useConfig();
  const [state, setState] = useState("signin");

  const styles = {
    centerAlign: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    smallButton: {
      fontSize: "0.6rem",
      cursor: "pointer",
    },
    tinyButton: {
      fontSize: "0.5rem",
      cursor: "pointer",
    },
    iconTextContainer: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
  };

  useEffect(() => {
    if (state === "minimized") {
      setModeGlobal("draw");
    } else if (
      state === "signin" ||
      state === "signup" ||
      state === "recoverpassword"
    ) {
      setModeGlobal("demo");
    }
  }, [state, setModeGlobal]);

  const renderFormContent = () => {
    switch (state) {
      case "minimized":
        //setModeGlobal("draw");
        return <Minimized setState={setState} />;
      case "signin":
        //setModeGlobal("demo");
        return <SignInForm onSignin={onSignin} setState={setState} />;
      case "signup":
        //setModeGlobal("demo");
        return <SignUpForm onSignUp={onSignUp} setState={setState} />;
      case "recoverpassword":
        //setModeGlobal("demo");
        return (
          <RecoverPasswordForm
            onForgotPassword={onForgotPassword}
            setState={setState}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        className="container"
        style={{
          position: "fixed",
          top: modeGlobal === "demo" ? "50%" : "10px",
          left: modeGlobal === "demo" ? "50%" : "auto",
          right: modeGlobal === "demo" ? "auto" : "10px",
          transform:
            modeGlobal === "demo"
              ? "translate(-50%, -50%)"
              : "translate(0%, 0%)",
          maxWidth: "300px",
        }}
      >
        <Box className="form-box">
          {renderFormContent()}
          {(state === "signin" || state === "signup") && (
            <>
              <Divider sx={{ bgcolor: "secondary.light" }} />
              <div style={styles.centerAlign}>
                <Button
                  className="submit-button"
                  style={styles.smallButton}
                  onClick={() => setState("minimized")}
                >
                  Enable mode draw only
                </Button>
                <div style={styles.iconTextContainer}>
                  <YouTubeIcon />
                  <Button
                    className="submit-button"
                    style={styles.smallButton}
                    onClick={() =>
                      window.open("https://www.youtube.com", "_blank")
                    }
                  >
                    {state === "signin"
                      ? "Discover by video"
                      : "Learn with video"}
                  </Button>
                </div>
                <Button
                  className="submit-button"
                  style={styles.tinyButton}
                  onClick={() => setIsVisibleTOU(true)}
                >
                  Terms of use
                </Button>
              </div>
            </>
          )}
        </Box>
      </Card>
    </div>
  );
}

export default LoginForm;
