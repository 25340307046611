import React, { useEffect, useState, useRef } from "react";

import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";

import { useConfig } from "../../ConfigContext";

import { Stack } from "@mui/material";

function AudioRoom() {
  const {
    socket,
    user,
    geodocUsers,
    setGeodocUsers,
    workspaceUsers,
    setWorkspaceUsers,
  } = useConfig();
  const [calling, setCalling] = useState(false);
  const [voicing, setVoicing] = useState(false);

  const localStream = useRef(null);
  const peerConnection = useRef(null);
  const remoteAudioRef = useRef(null);
  const iceCandidatesQueue = useRef([]); // Stocker les candidats en attente

  const servers = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
    ],
  };

  const playTestSound = () => {
    if (remoteAudioRef.current) {
      const audioContext = new AudioContext();
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      // Connecter l'oscillateur à un gain (volume) et à la sortie
      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      // Configurer l'oscillateur pour produire un son
      oscillator.type = "sine"; // Type de son : sine, square, sawtooth, triangle
      oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // Fréquence en Hz (440 Hz = La)

      // Configurer le volume et démarrer l'oscillateur
      gainNode.gain.setValueAtTime(0.5, audioContext.currentTime); // Réduire le volume
      oscillator.start();

      // Arrêter après 1 seconde
      setTimeout(() => {
        oscillator.stop();
      }, 1000);
    }
  };

  // Obtenir le flux audio local
  useEffect(() => {
    if (localStream.current) {
      localStream.current.getTracks().forEach((track) => {
        track.enabled = voicing;
      });
    }
  }, [voicing]);

  useEffect(() => {
    const clean = () => {
      if (peerConnection.current) {
        peerConnection.current.close();
        peerConnection.current = null;
      }
      if (localStream.current) {
        localStream.current.getTracks().forEach((track) => track.stop());
        localStream.current = null;
      }
      socket.off("offer");
      socket.off("answer");
      socket.off("ice-candidate");
    };

    if (calling) {
      // Initialiser la connexion peer-to-peer
      peerConnection.current = new RTCPeerConnection(servers);

      // Gestion des ICE candidates
      peerConnection.current.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("ice-candidate", event.candidate);
        }
      };

      // Réception du flux audio distant
      peerConnection.current.ontrack = (event) => {
        if (remoteAudioRef.current) {
          remoteAudioRef.current.srcObject = event.streams[0];
          remoteAudioRef.current.play();
          remoteAudioRef.current.muted = false;
          remoteAudioRef.current.volume = 1.0;
        }
      };

      // Obtenir le flux audio local
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          localStream.current = stream;

          if (!voicing) {
            // Désactiver le micro si voicing est false
            localStream.current.getTracks().forEach((track) => {
              track.enabled = false;
            });
          }

          stream.getTracks().forEach((track) => {
            peerConnection.current.addTrack(track, stream);
          });

          // Lecture du son de test
          playTestSound();

          // Créer une offre
          return peerConnection.current.createOffer();
        })
        .then((offer) => {
          return peerConnection.current.setLocalDescription(offer);
        })
        .then(() => {
          // Envoyer l'offre au serveur
          socket.emit("offer", {
            offer: peerConnection.current.localDescription,
          });
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });

      socket.on("offer", ({ offer }) => {
        if (!peerConnection.current) {
          peerConnection.current = new RTCPeerConnection(servers);

          // Gestion des ICE candidates
          peerConnection.current.onicecandidate = (event) => {
            if (event.candidate) {
              socket.emit("ice-candidate", {
                candidate: event.candidate,
                senderId: socket.id,
              });
            }
          };

          // Réception des flux audio
          peerConnection.current.ontrack = (event) => {
            if (remoteAudioRef.current) {
              remoteAudioRef.current.srcObject = event.streams[0];
              remoteAudioRef.current.play();
            }
          };
        }

        // Définir la description distante
        if (offer && offer.type && offer.sdp) {
          peerConnection.current
            .setRemoteDescription(new RTCSessionDescription(offer))
            .then(() => {
              // Vérifier si des candidats ICE sont en attente
              iceCandidatesQueue.current.forEach((candidate) => {
                peerConnection.current
                  .addIceCandidate(new RTCIceCandidate(candidate))
                  .catch((error) =>
                    console.error("Error adding ICE candidate:", error)
                  );
              });
              iceCandidatesQueue.current = []; // Vider la file d'attente

              // Créer une réponse SDP
              return peerConnection.current.createAnswer();
            })
            .then((answer) => {
              return peerConnection.current.setLocalDescription(answer);
            })
            .then(() => {
              // Envoyer la réponse au pair
              socket.emit("answer", {
                answer: peerConnection.current.localDescription,
                senderId: socket.id,
              });
            })
            .catch((error) => {
              console.error("Error handling offer:", error);
            });
        } else {
          console.error("Invalid offer received:", offer);
        }
      });

      // Écouter les réponses du serveur
      socket.on("answer", ({ answer }) => {
        if (peerConnection.current) {
          if (answer && answer.type && answer.sdp) {
            peerConnection.current
              .setRemoteDescription(new RTCSessionDescription(answer))
              .then(() => {
                console.log("Remote description set successfully.");
              })
              .catch((error) => {
                console.error("Error setting remote description:", error);
              });
          }
        }
      });

      // Écouter les ICE candidates du serveur
      socket.on("ice-candidate", (candidate) => {
        const iceCandidate = new RTCIceCandidate(candidate);
        peerConnection.current.addIceCandidate(iceCandidate).catch((error) => {
          console.error("Error adding received ice candidate", error);
        });
      });
    } else {
      // Nettoyer les ressources
      clean();
    }

    return () => {
      // Nettoyer les ressources
      clean();
    };
  }, [calling]);

  return (
    <Stack direction="row" spacing={2}>
      {/* Élément audio pour le son distant */}
      <audio ref={remoteAudioRef} autoPlay />
      {calling ? (
        <>
          <IconButton className="icon-button" onClick={() => setCalling(false)}>
            <PhoneDisabledIcon />
          </IconButton>

          {voicing ? (
            <IconButton
              className="icon-button"
              onClick={() => setVoicing(false)}
            >
              <MicIcon />
            </IconButton>
          ) : (
            <IconButton
              className="icon-button"
              onClick={() => setVoicing(true)}
            >
              <MicOffIcon />
            </IconButton>
          )}
        </>
      ) : (
        <IconButton className="icon-button" onClick={() => setCalling(true)}>
          <PhoneEnabledIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export default AudioRoom;
