import React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../../styles/loginform.css";

function Minimized({ setState }) {
  return (
    <div
      className="toggle-icon"
      onClick={() => {
        setState("signin");
      }}
    >
      <AccountCircleIcon /> Sign In or Sign Up
    </div>
  );
}

export default Minimized;
