// theme.js
import { createTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[900], // Couleur bleu marine pour les éléments principaux
    },
    secondary: {
      main: grey[700], // Couleur secondaire (facultatif)
    },
    background: {
      default: blue[900], // Couleur de fond bleu marine
      paper: "#213d64", // Couleur des éléments de papier
    },
    text: {
      primary: "#ffffff", // Couleur du texte principale blanche
      secondary: "#f0f0f0", // Couleur du texte secondaire (facultatif)
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Couleur blanche pour le texte des boutons
          "&.Mui-disabled": {
            color: grey[500], // Couleur grise pour les boutons désactivés
            borderColor: grey[500], // Couleur du cadre par défaut
          },
        },
        contained: {
          color: "#ffffff", // Couleur blanche pour le texte des boutons contenus
        },
        outlined: {
          color: "#ffffff", // Couleur blanche pour le texte des boutons outline
        },
        text: {
          color: "#ffffff", // Couleur blanche pour le texte des boutons text
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: "#ffffff", // Couleur blanche pour le texte des champs de texte
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(255, 255, 255, 0.5)", // Couleur du cadre par défaut
            },
            "&:hover fieldset": {
              borderColor: "#ffffff", // Couleur du cadre au survol
            },
            "&.Mui-focused fieldset": {
              borderColor: "#ffffff", // Couleur du cadre lorsqu'il est sélectionné
            },
          },
          "& .MuiInputLabel-root": {
            color: "#ffffff", // Couleur blanche pour le label
            "&.Mui-focused": {
              color: "#ffffff", // Couleur blanche pour le label lorsqu'il est sélectionné
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Couleur blanche pour les icônes
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Couleur blanche pour les icônes SVG
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
          borderRadius: "8px",
          color: "#f2f3ee",
          backgroundColor: "#1e2938",
        },
      },
    },
  },
});

export default theme;
