import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemButton,
  List,
} from "@mui/material";
import { useConfig } from "../../../ConfigContext";
import {
  createLayerIntoGeodoc,
  getLayersFromWorkspace,
} from "../../../services/layerService";

const AddLayerForm = ({ handleMenuClose }) => {
  const { token, workspaceSelected, geodocSelectedId, layers, setLayers } =
    useConfig();
  const [tabValue, setTabValue] = useState(0);
  const [nameLayer, setNameLayer] = useState("");
  const [typeLayer, setTypeLayer] = useState("URL");
  const [urlLayer, setUrlLayer] = useState("");
  const [layersWMS, setLayersWMS] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [layerId, setLayerId] = useState(null);

  // Nouveaux états pour les couches du workspace et la recherche
  const [workspaceLayers, setWorkspaceLayers] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (workspaceSelected && token) {
      const fetchLayers = async () => {
        const layersTmp = await getLayersFromWorkspace(
          token,
          workspaceSelected._id
        );
        // retirer les layer déjà présent dans layers
        layersTmp &&
          setWorkspaceLayers(
            layersTmp.filter(
              (layer) => !layers.find((item) => item._id === layer._id)
            )
          );

        //layersTmp && setWorkspaceLayers(layersTmp);
      };
      fetchLayers();
    }
  }, [workspaceSelected, token]);

  const handleAddLayer = async (layerInfo) => {
    const { nameLayer, type, urlLayer, layersWMS, isShared, layerId } =
      layerInfo;

    if (
      !nameLayer ||
      !type ||
      ((type === "WMS" || type === "TMS" || type === "WMTS") && !urlLayer) ||
      (type === "STORED" && !layerId)
    )
      return;

    try {
      const layerData = {
        name: nameLayer,
        type: type,
        url: type === "WMS" || type === "TMS" ? urlLayer : undefined,
        layers: type === "WMS" ? layersWMS : undefined,
        workspaceId: workspaceSelected._id,
        geodocId: geodocSelectedId,
        shared: isShared,
        layerStoreId: type === "STORED" ? layerId : undefined,
      };

      const response = await createLayerIntoGeodoc(
        token,
        workspaceSelected._id,
        geodocSelectedId,
        layerData
      );

      /*const response = await axios.post(
        `${process.env.REACT_APP_API_URL}layers/`,
        layerData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );*/
      response &&
        response.data &&
        setLayers([...(layers || []), response.data]);

      handleMenuClose();
    } catch (error) {
      console.error("Error adding layer:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Drawing" />
        <Tab label="Store" />
        <Tab label="External" />
      </Tabs>
      {tabValue === 0 && (
        <div style={{ padding: "20px" }}>
          <TextField
            label="Name"
            value={nameLayer}
            onChange={(e) => setNameLayer(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            margin="dense"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isShared}
                onChange={(e) => setIsShared(e.target.checked)}
              />
            }
            label="Shared"
          />
          <Button
            onClick={() =>
              handleAddLayer({ nameLayer, type: "FeatureCollection", isShared })
            }
            variant="contained"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Add
          </Button>
          <Button onClick={handleMenuClose} style={{ marginTop: "10px" }}>
            Cancel
          </Button>
        </div>
      )}
      {tabValue === 1 && (
        <div style={{ padding: "20px" }}>
          {workspaceLayers && workspaceLayers.length > 0 ? (
            <>
              <TextField
                label="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                fullWidth
                margin="dense"
                onKeyDown={(e) => e.stopPropagation()}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Layer</InputLabel>
                <List>
                  {workspaceLayers
                    .filter((layer) =>
                      layer.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map((layer) => (
                      <ListItemButton
                        key={layer._id}
                        selected={layerId === layer._id}
                        onClick={() => {
                          setNameLayer(layer.name);
                          setLayerId(layer._id);
                        }}
                        style={{
                          fontWeight: layerId === layer._id ? "bold" : "normal",
                        }}
                      >
                        {layer.name}
                      </ListItemButton>
                    ))}
                </List>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
                onClick={() =>
                  handleAddLayer({ nameLayer, type: "STORED", layerId })
                }
              >
                Add
              </Button>
              <Button onClick={handleMenuClose} style={{ marginTop: "10px" }}>
                Cancel
              </Button>
            </>
          ) : (
            <>No Layer shared</>
          )}
        </div>
      )}
      {tabValue === 2 && (
        <div style={{ padding: "20px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeLayer}
              label="Type Layer"
              onChange={(e) => setTypeLayer(e.target.value)}
            >
              <MenuItem value="WMS">WMS</MenuItem>
              <MenuItem value="TMS">TMS</MenuItem>
              <MenuItem value="WMTS">WMTS</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Name"
            value={nameLayer}
            onChange={(e) => setNameLayer(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            margin="dense"
          />
          <TextField
            label="URL"
            value={urlLayer}
            onChange={(e) => setUrlLayer(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            margin="dense"
            style={{ marginTop: "10px" }}
          />
          <TextField
            label="layersWMS"
            value={layersWMS}
            onChange={(e) => setLayersWMS(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            margin="dense"
            style={{ marginTop: "10px" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isShared}
                onChange={(e) => setIsShared(e.target.checked)}
              />
            }
            label="Shared"
          />
          <Button
            onClick={() =>
              handleAddLayer({
                nameLayer,
                type: typeLayer,
                urlLayer,
                layersWMS,
                isShared,
              })
            }
            variant="contained"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Add
          </Button>
          <Button onClick={handleMenuClose} style={{ marginTop: "10px" }}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddLayerForm;
