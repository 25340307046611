import React, { useState } from "react";

import { Button, TextField, Typography } from "@mui/material";
import "../../styles/loginform.css";
import { useConfig } from "../../ConfigContext";
import logImage from "../../images/logo512.png";

function SignInForm({ setState }) {
  const { setToken, setError } = useConfig();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)); // Vérification simplifiée de l'email
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    onSignin(email, password);
  };

  const onSignin = async (mail, password) => {
    try {
      const url = process.env.REACT_APP_API_URL + "users/login";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mail, password }),
      });
      if (response.ok) {
        const data = await response.json();
        setToken(data.token);
      } else {
        const error = await response.json();
        setError(error.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logImage}
        alt="Logo"
        style={{ width: "60px", marginBottom: "20px" }}
      />
      <Typography variant="h3" className="form-header">
        Sign In
        <Button className="toggle-button" onClick={() => setState("signup")}>
          or Sign Up ?
        </Button>
      </Typography>
      <TextField
        className="text-field"
        margin="normal"
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
        helperText={emailError ? "Please enter a valid email" : ""}
        placeholder="please enter your email"
      />
      <TextField
        className="text-field"
        margin="normal"
        label="Password"
        type="password"
        variant="outlined"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        placeholder="please enter your password"
      />
      <Button
        variant="contained"
        className="submit-button"
        disabled={emailError || email === ""}
        onClick={handleSubmit}
      >
        Sign In
      </Button>
      <Button
        className="forget-password"
        onClick={() => setState("recoverpassword")}
      >
        Forgot password?
      </Button>
    </div>
  );
}

export default SignInForm;
