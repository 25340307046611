import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import "../../styles/user.css";
import { Card, Button, Alert, Divider } from "@mui/material";
import { useConfig } from "../../ConfigContext";

/**
 * Affiche le panel du Chat Room.
 * @param {parms} desc
 */
function UserSettings() {
  const { user, setUser } = useConfig();

  const [isSettings, setIsSettings] = useState(false);
  const [isUpdateUsername, setIsUpdateUsername] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleUpdateUsername = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + "user/update-username";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: newUsername, id: user._id }),
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        alert("Username updated successfully");
      } else {
        const error = await response.json();
        alert("Username update failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  const handleDeleteAccount = async (req, res) => {
    try {
      const url = process.env.REACT_APP_API_URL + "user/delete/" + user._id;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: user.id }),
      });

      if (response.ok) {
        setUser(null); // Clears the user state
        alert("Account deleted successfully");
      } else {
        const error = await response.json();
        alert("Account deletion failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };
  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
  };

  return (
    <>
      Your Mail : {user.mail}
      Your Name :
      <input
        type="text"
        value={user.username}
        onChange={(e) => setNewUsername(e.target.value)}
        placeholder="New username"
      />
      <Divider sx={{ bgcolor: "primary.light" }} />
      <Button onClick={() => setIsChangePassword(true)}>Change Password</Button>
      {isChangePassword && (
        <>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New password"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
          />
          <button onClick={handleChangePassword}>Submit New Password</button>
          <button onClick={() => setIsChangePassword(false)}>Cancel</button>
        </>
      )}
      <Alert variant="filled" severity="error">
        <Button onClick={handleDeleteAccount}>DELETE ACCOUNT</Button>
      </Alert>
    </>
  );
}

export default UserSettings;
