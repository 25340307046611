import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import "../../styles/loginform.css";

function TermsOfUse({ setIsVisibleTOU }) {
  const handleClose = () => {
    setIsVisibleTOU(false);
  };

  return (
    <Card
      className="terms-card"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "300px",
        zIndex: "1000",
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to our application. Please read these terms of use carefully
          before using our services.
        </Typography>
        <Typography variant="body2" paragraph>
          1. **Acceptance of Terms:** By accessing or using our service, you
          agree to be bound by these terms. If you disagree with any part of the
          terms, you may not access the service.
        </Typography>
        <Typography variant="body2" paragraph>
          2. **Modifications:** We reserve the right to modify these terms at
          any time. Your continued use of the service after any such changes
          constitutes your acceptance of the new terms.
        </Typography>
        <Typography variant="body2" paragraph>
          3. **User Responsibilities:** You agree not to use the service for any
          unlawful purpose and to comply with all applicable laws.
        </Typography>
        <Typography variant="body2" paragraph>
          4. **Termination:** We may terminate or suspend access to our service
          immediately, without prior notice, if you breach the terms.
        </Typography>
        <Typography variant="body2" paragraph>
          5. **Governing Law:** These terms are governed by and construed in
          accordance with the laws of [Your Country], and you submit to the
          exclusive jurisdiction of the courts located in [Your Country].
        </Typography>
        <Typography variant="body2" paragraph>
          If you have any questions about these Terms, please contact us at
          [Your Contact Information].
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </CardContent>
    </Card>
  );
}

export default TermsOfUse;
