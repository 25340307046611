import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AdminPanel from "./components/Admin/AdminPanel.js";
import TestPanel from "./components/Admin/TestPanel.js";

const App = () => {
  console.log(process.env.REACT_APP_API_URL);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/*<Route path="/invit/:tokenInvit" element={<Home />} />*/}
      <Route path="/:funkytion/:token" element={<Home />} />
      <Route path="/admin" element={<AdminPanel />} />
      <Route path="/test" element={<TestPanel />} />
    </Routes>
  );
};

export default App;
