import React, { useEffect } from "react";
import axios from "axios";
import { useConfig } from "../../ConfigContext";
import WorkspacesAccordion from "./WorkspacesAccordion";
import LayersList from "./Layers/LayersList";
import "../../styles/configpanel.css";
import BackgroundPanel from "./BackgroundPanel";
import GeodocsAccordion from "./GeoDocsAccordion";

import { Stack } from "@mui/material";

/**
 * Bandeau de gauche. Englobe : WorkspacePanel, ProfilPanel, EventPanel, LayerPanel, BackgroundPanel.
 */
function ConfigPanel({ actualLocation, setMoveTo }) {
  const {
    user,
    token,
    workspaceSelected,
    setLayers,
    geodocSelectedId,
    setGeodocSelectedId,
    setGeodocs,
  } = useConfig();

  useEffect(() => {
    //  Si tu as selectionné un workspace
    if (workspaceSelected) {
      // Tu bouges jusqu'a lui
      setMoveTo(workspaceSelected.profile.location);
      // Tu récupères ses layers
      //fetchLayers();
      if (workspaceSelected.data) {
        if (
          workspaceSelected.data.geodocs &&
          workspaceSelected.data.geodocs.length > 0
        ) {
          // Tu mets à jour la liste de géodoc et tu selectionne le premier au passge
          setGeodocs(workspaceSelected.data.geodocs);
          setGeodocSelectedId(workspaceSelected.data.geodocs[0]._id);
        }
      }
    }
  }, [workspaceSelected]);

  const fetchLayers = async () => {
    if (workspaceSelected) {
      if (
        workspaceSelected.data.layers &&
        workspaceSelected.data.layers.length > 0
      ) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}layers/list`,
            {
              workspaceId: workspaceSelected._id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const tmpLayers = response.data.map((layer) => ({
            ...layer,
            visibility: true,
          }));
          setLayers(tmpLayers);
        } catch (error) {
          console.error("Erreur lors de la récupération des layers :", error);
        }
      } else {
        setLayers([]);
      }
    }
  };

  return (
    <>
      {user && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            position: "fixed",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              position: "fixed",
              zIndex: 1000,
            }}
          >
            <WorkspacesAccordion actualLocation={actualLocation} />
            {workspaceSelected && <GeodocsAccordion />}
          </div>
          {geodocSelectedId && (
            <>
              <LayersList />
              <BackgroundPanel />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ConfigPanel;
