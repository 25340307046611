import axios from "axios";

/**
 * Renvoie la liste des workspaces de l'utilisateur authentifié
 */
export const getMyWorkspaces = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des workspaces :", error);
    throw error;
  }
};

/**
 * Renvoie la liste de tous les workspaces si l'utilisateur est un super-administrateur
 */
export const getAllWorkspaces = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}workspaces/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération de tous les workspaces :",
      error
    );
    throw error;
  }
};

/**
 * Permet de créer un workspace
 */
export const createWorkspace = async (token, name, location) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces`,
      { name: name, location: location },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du workspace :", error);
    throw error;
  }
};

/**
 * Permet de supprimer un workspace
 */
export const deleteWorkspace = async (token, workspaceId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du workspace :", error);
    throw error;
  }
};

/**
 * Permet de modifier un workspace
 */
export const editWorkspace = async (token, workspaceId, newName) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}`,
      { name: newName },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du workspace :", error);
    throw error;
  }
};

/**
 * Envoyer une invitation à un utilisateur pour rejoindre un workspace
 * @param {string} token - Token JWT de l'utilisateur authentifié
 * @param {string} email - Email de l'utilisateur à inviter
 * @param {string} workspaceId - ID du workspace
 */
export const inviteUserToWorkspace = async (token, workspaceId, email) => {
  console.log("inviteUserToWorkspace", email, workspaceId);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/invit`,
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'invitation de l'utilisateur :", error);
    throw error;
  }
};

export const acceptWorkspaceInvitation = async (token, tokenInvit) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}workspaces/invitation/${tokenInvit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'acceptation de l'invitation :", error);
    throw error;
  }
};
