import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Menu,
  TextField,
  MenuItem,
  Divider,
  Accordion,
  Stack,
  Card,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useConfig } from "../../ConfigContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MapIcon from "@mui/icons-material/Map";
import {
  getMyWorkspaces,
  createWorkspace,
  deleteWorkspace,
  editWorkspace,
  inviteUserToWorkspace,
  acceptWorkspaceInvitation,
} from "../../services/workspaceService";

const WorkspacesAccordion = ({ actualLocation }) => {
  const {
    token,
    workspaceSelected,
    setWorkspaceSelected,
    workspaces,
    setWorkspaces,
    setCleanLayer,
  } = useConfig();
  const [anchorElAddWorkspace, setAnchorElAddWorkspace] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [nameWorkspace, setNameWorkspace] = useState("");
  const [nameNewWorkspace, setNameNewWorkspace] = useState("");
  const [tokenNewWorkspace, setTokenNewWorkspace] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [mailGuest, setMailGuest] = useState("");

  useEffect(() => {
    if (token) {
      fetchWorkspaces();
    }
  }, [token]);

  useEffect(() => {
    workspaceSelected && setNameWorkspace(workspaceSelected.profile.name);
    setCleanLayer(true);
  }, [workspaceSelected]);

  // Fonction pour récupérer les workspaces
  const fetchWorkspaces = async () => {
    try {
      const fetchedWorkspaces = await getMyWorkspaces(token); // Appel au service
      setWorkspaces(fetchedWorkspaces);

      // Si le workspace sélectionné a été supprimé, sélectionner le premier de la liste
      if (!fetchedWorkspaces.find((ws) => ws._id === workspaceSelected?._id)) {
        setWorkspaceSelected(fetchedWorkspaces[0] || null); // Sélectionner le premier workspace s'il y en a un, sinon null
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des workspaces :", error);
    }
  };

  // Fonction pour créer un workspace
  const handleCreateWorkspace = async () => {
    try {
      const newWorkspace = await createWorkspace(
        token,
        nameNewWorkspace,
        actualLocation
      ); // Appel au service

      setExpanded(false);

      // Rafraîchir la liste des workspaces après création
      const updatedWorkspaces = await getMyWorkspaces(token);
      setWorkspaces(updatedWorkspaces);

      // Sélectionner immédiatement le workspace nouvellement créé
      setWorkspaceSelected(newWorkspace);

      setAnchorElAddWorkspace(null); // Fermer le menu après création

      setNameNewWorkspace("");
    } catch (error) {
      console.error("Erreur lors de la création du workspace :", error);
    }
  };

  const handleJoinWorkspace = async () => {
    try {
      acceptWorkspaceInvitation(token, tokenNewWorkspace);
      setAnchorElAddWorkspace(null); // Fermer le menu après création
      setTokenNewWorkspace("");
    } catch (error) {
      console.error("Erreur lors de la création du workspace :", error);
    }
  };

  // Fonction pour supprimer un workspace
  const handleDelWorkspace = async () => {
    try {
      await deleteWorkspace(token, workspaceSelected._id); // Appel au service

      // Supprimer immédiatement le workspace sélectionné et réinitialiser la sélection
      const updatedWorkspaces = workspaces.filter(
        (ws) => ws._id !== workspaceSelected._id
      );
      setWorkspaces(updatedWorkspaces);

      // Sélectionner le premier workspace disponible ou null si aucun workspace restant
      setWorkspaceSelected(updatedWorkspaces[0] || null);

      setAnchorElSettings(null); // Fermer le menu après suppression
      setIsDeleting(false); // Réinitialiser l'état
    } catch (error) {
      console.error("Erreur lors de la suppression du workspace :", error);
    }
  };

  const handleInviteGuest = async (workspaceId, mail) => {
    try {
      await inviteUserToWorkspace(token, workspaceId, mail); // Appel au service
      setIsInviting(false); // Réinitialiser l'état
    } catch (error) {
      console.error("Erreur lors de l'invitation du guest :", error);
    }
  };

  // Fonction pour renommer un workspace
  const handleRenameWorkspace = async (workspaceId, newName) => {
    try {
      await editWorkspace(token, workspaceId, newName); // Appel au service

      // Mettre à jour directement le nom du workspace dans la liste sans recharger
      const updatedWorkspaces = workspaces.map((ws) =>
        ws._id === workspaceId
          ? { ...ws, profile: { ...ws.profile, name: newName } }
          : ws
      );
      setWorkspaces(updatedWorkspaces);

      // Si le workspace renommé est celui sélectionné, mettre à jour sa sélection
      if (workspaceSelected && workspaceSelected._id === workspaceId) {
        setWorkspaceSelected({
          ...workspaceSelected,
          profile: { ...workspaceSelected.profile, name: newName },
        });
      }

      setAnchorElSettings(null); // Fermer le menu après modification
      setIsRenaming(false); // Réinitialiser l'état
    } catch (error) {
      console.error("Erreur lors de la modification du workspace :", error);
    }
  };

  const handleMenuOpen = (setAnchor) => (event) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor, resetState) => () => {
    setAnchor(null);
    if (resetState) resetState();
  };

  const renderWorkspacesListAccordion = () => (
    <AccordionDetails>
      <Stack spacing={2}>
        {workspaces &&
          workspaces.length > 1 &&
          workspaces.map(
            (workspace, index) =>
              (!workspaceSelected ||
                workspace._id !== workspaceSelected._id) && (
                <Button
                  key={workspace._id || index}
                  onClick={() => {
                    setExpanded(false);
                    setWorkspaceSelected(workspace);
                  }}
                >
                  {workspace.profile.name}
                </Button>
              )
          )}
        {workspaces && workspaces.length > 1 && <Divider sx={{ my: 0.5 }} />}
        <Button
          aria-label="Add Workspace"
          onClick={handleMenuOpen(setAnchorElAddWorkspace)}
        >
          New Workspace
        </Button>
      </Stack>
    </AccordionDetails>
  );

  const renderAddWorkspaceMenu = () => (
    <Menu
      anchorEl={anchorElAddWorkspace}
      open={Boolean(anchorElAddWorkspace)}
      onClose={handleMenuClose(setAnchorElAddWorkspace)}
    >
      <div className="add-form">
        <TextField
          label="Name Workspace"
          value={nameNewWorkspace}
          onChange={(e) => setNameNewWorkspace(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          fullWidth
          margin="dense"
        />
        <Button
          onClick={handleCreateWorkspace}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
        >
          Create
        </Button>
        <Button
          onClick={handleMenuClose(setAnchorElAddWorkspace)}
          style={{ marginTop: "10px" }}
        >
          Cancel
        </Button>
        <TextField
          label="Token Workspace"
          value={tokenNewWorkspace}
          onChange={(e) => setTokenNewWorkspace(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          fullWidth
          margin="dense"
        />
        <Button
          onClick={handleJoinWorkspace}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
        >
          Join
        </Button>
      </div>
    </Menu>
  );

  const renderSettingsMenu = () => (
    <Menu
      anchorEl={anchorElSettings}
      open={Boolean(anchorElSettings)}
      onClose={handleMenuClose(setAnchorElSettings, () => {
        setIsRenaming(false);
        setIsDeleting(false);
      })}
    >
      {!isRenaming &&
        !isDeleting &&
        !isInviting && [
          <MenuItem key="invit" onClick={() => setIsInviting(true)}>
            <Button>Invit</Button>
          </MenuItem>,
          <MenuItem key="update" onClick={() => setIsRenaming(true)}>
            <Button>Update Workspace Name</Button>
          </MenuItem>,
          <MenuItem key="delete" onClick={() => setIsDeleting(true)}>
            <Button>Delete Workspace</Button>
          </MenuItem>,
        ]}
      {isRenaming && (
        <MenuItem>
          <div className="add-form">
            <TextField
              label="Name Workspace"
              value={nameWorkspace}
              onChange={(e) => setNameWorkspace(e.target.value)}
              fullWidth
              margin="dense"
            />
            <Button
              variant="contained"
              onClick={() =>
                handleRenameWorkspace(workspaceSelected._id, nameWorkspace)
              }
            >
              Update name
            </Button>
          </div>
        </MenuItem>
      )}
      {isInviting && (
        <MenuItem>
          <div className="add-form">
            <TextField
              label="Mail Guest"
              value={mailGuest}
              onChange={(e) => setMailGuest(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              fullWidth
              margin="dense"
            />
            <Button
              variant="contained"
              onClick={() =>
                handleInviteGuest(workspaceSelected._id, mailGuest)
              }
            >
              Send Invitation
            </Button>
          </div>
        </MenuItem>
      )}
      {isDeleting && (
        <MenuItem onClick={handleDelWorkspace}>
          <Button variant="contained" color="error">
            Delete Workspace
          </Button>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <Accordion
        style={{
          marginTop: "10px",
          marginLeft: "10px",
          padding: "4px",
          zIndex: 1000,
        }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        {workspaceSelected &&
          workspaceSelected.profile &&
          workspaceSelected.profile.name && (
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <MapIcon />
              {workspaceSelected.profile.name}
            </AccordionSummary>
          )}
        {renderWorkspacesListAccordion()}
        <div className="left-top-div">{renderAddWorkspaceMenu()}</div>
      </Accordion>
      {workspaceSelected && (
        <>
          <IconButton
            onClick={handleMenuOpen(setAnchorElSettings)}
            className="icon-button"
          >
            <MoreVertIcon />
          </IconButton>
          {renderSettingsMenu()}
        </>
      )}
    </>
  );
};

export default WorkspacesAccordion;
