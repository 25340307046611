import React, { useState, useEffect } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import "../../styles/dynamic-marker.css";

// Component for a single marker with dynamic icon
const UserMarker = ({ coordinates, name }) => {
  const [customIcon, setCustomIcon] = useState(null);
  const size = 32;

  useEffect(() => {
    const createCustomIcon = async () => {
      try {
        const response = await fetch("/icons/location.svg");
        const svgText = await response.text();

        // Modify SVG to apply the dynamic color
        // Après avoir remplacé la couleur
        const coloredSVG = svgText.replace(/<svg([^>]*?)>/i, (match, p1) => {
          // Supprimer les attributs width et height existants
          let newAttributes = p1.replace(/\s*(width|height)=".*?"/gi, "");
          // Ajouter width="100%" et height="100%"
          return `<svg${newAttributes} width="100%" height="100%">`;
        });

        //const size = 64; // Icon size in pixels

        // Create a Leaflet icon
        const icon = L.divIcon({
          html: `<div style="display: flex; align-items: center; justify-content: center; width: ${size}px; height: ${size}px; border: none; padding: 0;color:black;font-weight:bold" >${coloredSVG}${name}</div>`,
          iconSize: [size, size],
          iconAnchor: [size / 2, size / 2], // Center the icon
        });

        setCustomIcon(icon);
      } catch (error) {
        console.error("Failed to load or process the SVG:", error);
      }
    };

    createCustomIcon();
  }, [coordinates]); // Update icon if iconPath or color changes

  // Render marker only if the custom icon is ready
  return customIcon ? (
    <Marker position={coordinates} icon={customIcon} />
  ) : null;
};

export default UserMarker;
