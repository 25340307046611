import React, { useState, useEffect } from "react";

import { Button, TextField, Typography } from "@mui/material";
import "../../styles/loginform.css";
import { useConfig } from "../../ConfigContext";
import logImage from "../../images/logo512.png";

function SignUpForm({ setState }) {
  const { setMessage, setError } = useConfig();
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    setPasswordError(password !== confirmPassword);
  }, [password, confirmPassword]);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)); // Vérification simplifiée de l'email
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    onSignUp(email, firstname, lastname, password);
  };

  const onSignUp = async (mail, firstname, lastname, password) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "users/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mail, firstname, lastname, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
      } else {
        const error = await response.json();
        setError(error.message);
      }
    } catch (error) {
      //alert("An error occurred: " + error.message);

      console.log("ytjtyj " + error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logImage}
        alt="Logo"
        style={{ width: "60px", marginBottom: "20px" }}
      />
      <Typography variant="h3" className="form-header">
        Sign Up
        <Button className="toggle-button" onClick={() => setState("signin")}>
          or Sign In ?
        </Button>
      </Typography>
      <TextField
        className="text-field"
        margin="normal"
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
        helperText={emailError ? "Please enter a valid email" : ""}
        placeholder="please enter your email"
      />

      <TextField
        className="text-field"
        margin="normal"
        label="First Name"
        type="string"
        variant="outlined"
        onChange={(e) => {
          setFirstname(e.target.value);
        }}
        placeholder="please enter your first name"
      />

      <TextField
        className="text-field"
        margin="normal"
        label="Last Name"
        type="string"
        variant="outlined"
        onChange={(e) => {
          setLastname(e.target.value);
        }}
        placeholder="please enter your last name"
      />

      <TextField
        className="text-field"
        margin="normal"
        label="Password"
        type="password"
        variant="outlined"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        placeholder="please enter your password"
      />

      <TextField
        className="text-field"
        margin="normal"
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        error={passwordError}
        helperText={passwordError ? "Please enter same password" : ""}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
        }}
        variant="outlined"
        placeholder="please confirm your password"
      />

      <Button
        variant="contained"
        className="submit-button"
        disabled={
          emailError ||
          email === "" ||
          password !== confirmPassword ||
          password === "" ||
          firstname === ""
        }
        onClick={handleSubmit}
      >
        Sign Up
      </Button>
    </div>
  );
}

export default SignUpForm;
