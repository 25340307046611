import React, { useEffect, useState } from "react";
import axios from "axios";

import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import "../../styles/user.css";
import { Card, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { useConfig } from "../../ConfigContext";
import MailIcon from "@mui/icons-material/Mail";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import UserSettings from "./UserSettings";
import Inbox from "./Inbox";
import ChatRoom from "./ChatRoom";

/**
 * Affiche le panel du user en haut à droite. Composé de Inbox, ChatRoom, UserSettings
 * @param {setIsVisibleTOU} Le bouton des TOU est dans ce panel. A déplacer au niveau supérieur ?
 * @returns
 */
function UserPanel({ setIsVisibleTOU }) {
  const { user, setUser, token, setModeGlobal, socket, setToken } = useConfig();

  const [minimized, setMinimized] = useState(false);

  const [mode, setMode] = useState(""); // mode enum : inbox, chat, settings,
  const [isUpdateUsername, setIsUpdateUsername] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    // Fonction pour récupérer les informations de l'utilisateur
    const fetchUser = async () => {
      try {
        // Si le token n'est pas défini, ne pas exécuter l'appel à l'API
        if (!token) {
          return;
        }

        // Appel à l'API pour récupérer les informations de l'utilisateur
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "users",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Mise à jour de l'état utilisateur avec les données récupérées
        setUser(response.data);
      } catch (error) {
        // Mise à jour de l'état d'erreur en cas de problème
        console.error("Failed to fetch user information.", error);
      }
    };

    fetchUser();
  }, [token]);

  const handleLogout = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "users/logout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify({ token: user.token }), // Assuming the token is part of the user object
        }
      );

      if (response.ok) {
        setUser(null); // Clears the user state, effectively logging out the user
        setModeGlobal("signin");
        setToken(null);
        socket.close();
      } else {
        const error = await response.json();
        alert("Logout failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  return (
    user && (
      <Card
        className="container"
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          transform: "translate(0%, 0%)",
          maxWidth: "300px",
          padding: "10px",
        }}
      >
        <>
          {/* ------ Icones du haut */}

          <Stack direction="row" spacing={2}>
            {!minimized && (
              <Stack direction="row" spacing={1}>
                {/*<IconButton
                  disabled
                  onClick={() =>
                    mode === "inbox" ? setMode("") : setMode("inbox")
                  }
                  className="icon-button"
                >
                  <MailIcon style={{ color: "grey" }} />
                </IconButton>*/}

                <IconButton
                  onClick={() =>
                    mode === "chat" ? setMode("") : setMode("chat")
                  }
                  className="icon-button"
                >
                  <PermPhoneMsgIcon />
                </IconButton>

                <IconButton
                  onClick={() =>
                    mode === "settings" ? setMode("") : setMode("settings")
                  }
                  className="icon-button"
                >
                  <SettingsIcon />
                </IconButton>

                <IconButton onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </Stack>
            )}
            <Badge
              color="secondary"
              onClick={() => {
                setMinimized((minimized) => !minimized);
                !minimized && setMode("");
              }}
            >
              <Avatar>
                {user &&
                  user.firstname &&
                  user.firstname.charAt(0).toUpperCase()}
                {user && user.lastname && user.lastname.charAt(0).toUpperCase()}
              </Avatar>
            </Badge>
          </Stack>

          {/* -------- Affichage de la partie du bas */}
          {!minimized && (
            <>
              {mode === "inbox" && <Inbox />}
              {mode === "chat" && <ChatRoom />}
              {mode === "settings" && <UserSettings />}
            </>
          )}
        </>
      </Card>
    )
  );
}

export default UserPanel;
