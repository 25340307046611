import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import io from "socket.io-client";
/*export const socket = io("http://localhost:3010", {
  transports: ["websocket", "polling"],
});*/

const ConfigContext = createContext({
  color: "blue", // default color
  setColor: () => {}, // default empty function
  mode: "mouse", // default mode
  setMode: () => {}, // function to set mode
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }) => {
  const [color, setColor] = useState("#ff0000");
  const [mode, setMode] = useState("mouse");
  const [idSelectedFeature, setIdSelectedFeature] = useState();
  const [features, setFeatures] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);
  const [mapInit] = useState({ center: [51.505, -0.09], zoom: 13 });
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const socketRef = useRef(null);

  const [workspaces, setWorkspaces] = useState([]); // -> WorkspacesAccordion
  const [workspaceSelected, setWorkspaceSelected] = useState(); // -> WorkspacesAccordion

  const [geodocs, setGeodocs] = useState(); // -> WorkspacesAccordion
  const [geodocSelectedId, setGeodocSelectedId] = useState(); // -> GeodocsAccordion

  const [layers, setLayers] = useState(); // -> GeodocsAccordion
  const [layerSelectedId, setLayerSelectedId] = useState(); // -> LayersList

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [backgroundSelected, setBackgroundSelected] = useState();
  const [modeGlobal, setModeGlobal] = useState("signin");

  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [geodocUsers, setGeodocUsers] = useState([]);

  const [iconPath, setIconPath] = useState([]);
  const [cleanLayer, setCleanLayer] = useState(false);
  const [followUserId, setFollowUserId] = useState();

  useEffect(() => {
    if (user && token && !socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_API_URL_WS, {
        // TODO : Check if adress it's necessary in production mode
        transports: ["websocket"], // TODO : Check if it's necessary in production mode
        query: {
          token: token, // Send the token to the server
        },
      });
      socketRef.current.off("connect");
      socketRef.current.on("connect", () => {
        console.log("WebSocket connected");
      });

      socketRef.current.off("disconnect");
      socketRef.current.on("disconnect", () => {
        console.log("WebSocket disconnected");
      });
    }
    return () => {
      if (socketRef && socketRef.current) {
        socketRef.current.off("connect");
        socketRef.current.off("disconnect");
        socketRef.current.close();
      }
    };
  }, [user, token]);

  // if workspace change, send message "workspace" with workspace id
  useEffect(() => {
    if (socketRef.current && workspaceSelected) {
      socketRef.current.emit("workspace", workspaceSelected._id);
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off("workspace");
      }
    };
  }, [workspaceSelected]);

  // if geodoc change, send message "geodoc" with geodoc id
  useEffect(() => {
    if (socketRef.current && geodocSelectedId) {
      socketRef.current.emit("geodoc", geodocSelectedId);
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off("geodoc");
      }
    };
  }, [geodocSelectedId]);

  return (
    <ConfigContext.Provider
      value={{
        color,
        setColor,
        mode,
        setMode,
        idSelectedFeature,
        setIdSelectedFeature,
        features,
        setFeatures,
        backgrounds,
        setBackgrounds,
        mapInit,
        token,
        setToken,
        user,
        setUser,
        socket: socketRef.current,
        workspaces,
        setWorkspaces,
        workspaceSelected,
        setWorkspaceSelected,
        layers,
        setLayers,
        geodocs,
        setGeodocs,
        geodocSelectedId,
        setGeodocSelectedId,
        error,
        setError,
        message,
        setMessage,
        backgroundSelected,
        setBackgroundSelected,
        layerSelectedId,
        setLayerSelectedId,
        modeGlobal,
        setModeGlobal,
        workspaceUsers,
        setWorkspaceUsers,
        geodocUsers,
        setGeodocUsers,
        iconPath,
        setIconPath,
        cleanLayer,
        setCleanLayer,
        followUserId,
        setFollowUserId,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
