import React, { useState } from "react";

function LayerList() {
  const getLayers = async () => {
    try {
      const response = await fetch(process.env.URL + "/layer/list");
      const data = await response.json();
    } catch (error) {}
  };
  return <></>;
}

export default LayerList;
