import React, { useEffect, useState, useRef } from "react";

import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import SendIcon from "@mui/icons-material/Send";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import AudioRoom from "./AudioRoom";

import "../../styles/user.css";
import {
  Card,
  Stack,
  TextField,
  List,
  ListItem,
  Accordion,
} from "@mui/material";
import { useConfig } from "../../ConfigContext";

/**
 * Affiche le panel du Chat Room.
 * @param {parms} desc
 */
function ChatRoom() {
  const {
    socket,
    user,
    geodocUsers,
    setGeodocUsers,
    workspaceUsers,
    setWorkspaceUsers,
    followUserId,
    setFollowUserId,
  } = useConfig();
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [offlineUsers, setOfflineUsers] = useState([]);
  const chatEndRef = useRef(null);

  const handleFollowUser = (user) => {
    console.log("Follow user", user);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        who: user,
        message: message,
      };
      console.log(user);
      // Envoyer le message au serveur
      setChatHistory([...chatHistory, newMessage]);
      socket.emit("message", message);
      setMessage("");
    }
  };

  useEffect(() => {
    if (workspaceUsers && geodocUsers) {
      // Utilisateurs en ligne : ceux présents dans le geodoc
      setOnlineUsers(geodocUsers);

      // Utilisateurs hors ligne : présents dans le workspace mais pas dans le geodoc
      const offline = workspaceUsers.filter(
        (user) => !geodocUsers.some((gUser) => gUser.id === user.id)
      );
      setOfflineUsers(offline);
    }
  }, [workspaceUsers, geodocUsers]);

  useEffect(() => {
    /*function onConnect() {
      console.log("connect socket io");
    }*/
    // Envoyer la position de la souris au serveur
    /*const handleMouseMove = (event) => {
      const position = { x: event.clientX, y: event.clientY };
      //console.log("mouseMove : " + position.x + " " + position.y);
      socket.emit("position", position);
    };*/

    // Écouter les positions des autres utilisateurs
    /*socket.on("positions", (positions) => {
      setPositions((prevPositions) => [...prevPositions, positions]);
    });*/

    socket.on("message", (message) => {
      console.log("message", message);
      setChatHistory((prevChatHistory) => [...prevChatHistory, message]);
    });

    // ecouter la reponse du serveur
    socket.on("listUsersRoomWorkspace", (users) => {
      setWorkspaceUsers(users);
    });

    // ecouter la reponse du serveur
    socket.on("listUsersRoomGeodoc", (users) => {
      setGeodocUsers(users);
    });

    // si un utilisateur se connecte a la room du geodoc alors on met a jour la liste des utilisateurs
    socket.on("userConnected", (user) => {
      // si l'utilisateur n'est pas deja dans la liste alors on l'ajoute
      if (!geodocUsers.some((u) => u.id === user.id))
        setGeodocUsers((prevUsers) => [...prevUsers, user]);
    });

    // si un utilisateur se deconnecte de la room du geodoc alors on met a jour la liste des utilisateurs
    socket.on("userDisconnected", (user) => {
      setGeodocUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
    });

    socket.on("chat", (data) => {
      if (data) {
        setChatHistory(data);
      }
    });

    // Demander la liste des utilisateurs du workspace s'il y en a un de sélectionné
    socket.emit("getUsersInWorkspace");

    // Demander la liste des utilisateurs du geodoc s'il y en a un de sélectionné
    socket.emit("getUsersInGeodoc");

    // Demander l'historique de la discussion
    socket.emit("getChatHistory");

    // Cleanup
    return () => {
      socket.off("message");
      socket.off("listUsersRoomWorkspace");
      socket.off("listUsersRoomGeodoc");
      socket.off("userConnected");
      socket.off("userDisconnected");
      socket.off("chat");
    };
  }, [socket]);

  /* Affichage de la discussion */

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  return (
    <>
      <h1>ChatRoom</h1>
      <Stack direction="row" spacing={2}>
        <AudioRoom />
      </Stack>
      {onlineUsers.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Geodoc Users
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {onlineUsers.map((user) => (
                <ListItem key={user.id}>
                  {user.firstname} {user.lastname}
                  <IconButton
                    className="icon-button"
                    onClick={() =>
                      followUserId
                        ? setFollowUserId(null)
                        : setFollowUserId(user.id)
                    }
                  >
                    <YoutubeSearchedForIcon
                      style={{
                        color: user.id === followUserId ? "red" : "inherit",
                      }}
                    />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      {offlineUsers.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Workspace Users
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {offlineUsers.map((user) => (
                <ListItem key={user.id}>
                  {user.firstname} {user.lastname}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      <h2>Chat</h2>
      <Stack spacing={2}>
        {/* Affichage de la discussion */}
        <div
          style={{
            height: "200px",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          {chatHistory.map((msg, index) => (
            <div
              key={index}
              style={{
                textAlign: msg.who?._id === user.id ? "left" : "right",
                margin: "5px 0",
              }}
              ref={chatEndRef}
            >
              <>
                <strong>
                  {msg.who?._id === user.id
                    ? "You "
                    : `${msg.who?.firstname ?? "Unknown"} ${
                        msg.who?.lastname ?? "User"
                      }`}
                  :
                </strong>{" "}
                {typeof msg.message === "string"
                  ? msg.message
                  : JSON.stringify(msg.message)}
              </>
            </div>
          ))}
        </div>

        {/* Champ de texte pour envoyer un message */}
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Écrire un message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <IconButton color="primary" onClick={handleSendMessage}>
            <SendIcon />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
}

export default ChatRoom;
