import React, { useEffect, useState } from "react";

function UserList() {
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await fetch(process.env.URL + "/user/list");
        const data = await response.json();
      } catch (error) {}
    };
  }, []);

  return <></>;
}

export default UserList;
